$fa-font-path: "../font";
$html-font-size: 16;
$c-white: #fff;
$c-black: #000;
$c-gainsboro: #DDE0D8;
$c-sunshade: #FA9C46;
$c-dune: #353031;
$c-transparent-black: #00000059;



$primary-font: Helvetica Neue, Helvetica, Arial, sans-serif;
$secondary-font: "dharma-gothic-c", sans-serif